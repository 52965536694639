module Main exposing (main)

import Browser
import Model exposing (init, update)
import Subscriptions exposing (subscriptions)
import Types exposing (Flags, Model, Msg(..))
import View exposing (view)



-- Main Program


main : Program Flags Model Msg
main =
    Browser.application
        { init = init
        , view = view
        , update = update
        , subscriptions = subscriptions
        , onUrlChange = OnUrlChange
        , onUrlRequest = OnUrlRequest
        }
