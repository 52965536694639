import { AnnotDocumentData, StorageCanvas, DocumentStatus } from './lib/types'
import { fabric } from 'fabric'
import { App } from '*.elm'
import { drawAnnotation } from './documentAnnotation'
import { encodeAnnot } from './lib/webviewer'
import * as PDFJS from 'pdfjs-dist/legacy/build/pdf.js'

type UpdateData = {
  dataList: AnnotDocumentData[]
  status: DocumentStatus
  base64Data: string
  pagesWithDeletedAnnot: number[]
}

export function updateDocument(app: App): (updateData: UpdateData) => void {
  return async function (updateData: UpdateData): Promise<void> {
    PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.min.js`
    const { dataList, status, base64Data, pagesWithDeletedAnnot } = updateData
    let pageNum = 1
    let storageString = sessionStorage.getItem(`fabricCanvas${pageNum}`)
    const canvasArray: StorageCanvas[] = []
    do {
      if (storageString != null) {
        canvasArray.push(JSON.parse(storageString))
        pageNum++
        storageString = sessionStorage.getItem(`fabricCanvas${pageNum}`)
      }
    } while (storageString != null)
    canvasArray.sort((a, b) => a.pageNum - b.pageNum)
    const annotList = dataList.map((annot) => annot.annotation)
    const loadingTask = PDFJS.getDocument(base64Data)

    await loadingTask.promise.then(async function (pdf) {
      for (let i = 1; i <= pdf.numPages; i++) {
        const canvas = canvasArray[i - 1]
        const annotInPage = annotList.filter(
          (annot) => annot.data.style.page === i,
        )
        if (
          annotInPage.length > 0 ||
          pagesWithDeletedAnnot.some((page) => page === i)
        ) {
          const { pageHeight, pageWidth } = canvas
          const page = await pdf.getPage(i)
          const canvasElement = await recreateCanvas(canvas, page)
          if (canvasElement) {
            canvasElement.clear()
            addListenerToCanvas(
              canvasElement,
              canvas.pageNum,
              app,
              pageWidth,
              pageHeight,
              status,
            )
            annotInPage.forEach((data) => {
              drawAnnotation(
                data,
                {
                  canvas: canvasElement,
                  pageHeight,
                  pageWidth,
                },
                status,
                app,
              )
            })
          }
        }
      }
    })
  }
}

//=========== Need to relocate this ===================================

function addListenerToCanvas(
  fabricCanvas: fabric.Canvas,
  pageNumber: number,
  app: App,
  pageWidth: number,
  pageHeight: number,
  status: DocumentStatus,
) {
  if (status === 'Upload') {
    fabricCanvas.on('mouse:down', (data) => {
      const { offsetX, offsetY } = data.e

      // Note: 0.62 is the magic number determined by Heuristic evaluation
      // Actual pixel to points conversion is 0.75 but there is some offset involved
      const offsetXPercentage = offsetX / fabricCanvas.getWidth()
      const offsetYPercentage = offsetY / fabricCanvas.getHeight()

      const pdfOffsetX = pageWidth * offsetXPercentage
      const pdfOffsetY = pageHeight * offsetYPercentage
      const clickData = { x: pdfOffsetX, y: pdfOffsetY, page: pageNumber }
      app.ports.receiveMultipleDocumentClickAnnotData.send(clickData)
      // Trigger the click event
    })
    fabricCanvas.on('text:editing:exited', (event) => {
      if (event.target != null && event.target.type === 'textbox') {
        const encodeData = encodeAnnot(
          event.target,
          pageNumber,
          fabricCanvas,
          pageWidth,
          pageHeight,
          // @ts-ignore
          event.target.text,
          // @ts-ignore
          event.target._textLines == null ? 1 : event.target._textLines.length,
        )
        app.ports.receiveMultipleDocumentAnnotData.send(encodeData)
      }
    })
    fabricCanvas.on('object:modified', (event) => {
      if (event.target == null) {
        return
      }
      const encodeData = encodeAnnot(
        event.target,
        pageNumber,
        fabricCanvas,
        pageWidth,
        pageHeight,
        null,
        // @ts-ignore
        event.target._textLines == null ? 1 : event.target._textLines.length,
      )
      app.ports.receiveMultipleDocumentAnnotData.send(encodeData)
      // // @ts-ignore
      // switch (event.action) {
      //   case 'drag':
      //     return console.log('Drag')
      //   case 'scaleX':
      //     return console.log('scale x')
      //   case 'scaleY':
      //     return console.log('scale y')
      //   case 'scale':
      //     return console.log('scale')
      //   default:
      //     console.log('Other Actions')
      // }
    })

    fabricCanvas.on('selection:created', (event) => {
      // Add ts-ignore as the structure for the selection:created event is different
      app.ports.receiveSelectedAnnotClickData.send({
        page: pageNumber,
        // @ts-ignore
        id: event.selected[0].data.id,
      })
    })
    fabricCanvas.on('selection:updated', (event) => {
      app.ports.receiveSelectedAnnotClickData.send({
        page: pageNumber,
        // @ts-ignore
        id: event.selected[0].data.id,
      })
    })
    fabricCanvas.on('selection:cleared', () => {
      app.ports.receiveDeselecedAnnotClickData.send({})
    })
  }

  // this Zoom method does not work as this is only for one canvas

  // fabricCanvas.on('mouse:wheel', (event) => {
  //   if (event.e.ctrlKey) {
  //     const delta = event.e.deltaY
  //     let zoom = fabricCanvas.getZoom() * 0.999 ** delta

  //     if (zoom > 20) zoom = 20
  //     if (zoom < 0.01) zoom = 0.01
  //     fabricCanvas.zoomToPoint({ x: event.e.offsetX, y: event.e.offsetY }, zoom)
  //     event.e.preventDefault()
  //     event.e.stopPropagation()
  //     console.log(event.e.ctrlKey)
  //   }
  // })
}

async function recreateCanvas(
  canvasData: StorageCanvas,
  page: PDFJS.PDFPageProxy,
): Promise<fabric.Canvas | null> {
  const canvasContainerElement = document.getElementById(
    `canvasContainer${canvasData.pageNum}`,
  )
  const canvasElement = document.getElementById(
    `canvas${canvasData.pageNum}`,
  ) as HTMLCanvasElement
  const canvasChild = canvasContainerElement?.lastChild
  if (canvasContainerElement && canvasChild) {
    canvasContainerElement.removeChild(canvasChild)
  }
  const webviewerNode = document.getElementById('pdfViewer')
  if (webviewerNode == null) {
    return null
  }
  const rect = webviewerNode.getBoundingClientRect()
  const canvas = document.createElement('CANVAS') as HTMLCanvasElement
  canvas.height = canvasElement == null ? rect.height : canvasElement.height
  canvas.width = canvasElement == null ? rect.width : canvasElement.width
  canvas.id = `canvas${canvasData.pageNum}`
  const context = canvas.getContext('2d')
  if (context == null) {
    return null
  }
  canvasContainerElement?.appendChild(canvas)
  const pageViewPort = page.getViewport({ scale: 1 })
  const scaleX = (rect.width * 0.9) / pageViewPort.width
  const updatedViewport = page.getViewport({ scale: scaleX })
  canvas.height = updatedViewport.height
  const renderContext = {
    canvasContext: context,
    viewport: updatedViewport,
  }
  await page.render(renderContext).promise
  const background = canvas.toDataURL('image/png')
  const canvasFabric = new fabric.Canvas(canvas)
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  canvasFabric.loadFromJSON(canvasData.canvas, () => {})
  canvasFabric.setBackgroundImage(
    background,
    canvasFabric.renderAll.bind(canvasFabric),
  )
  canvasFabric.setWidth(rect.width * 0.9)
  return canvasFabric
}

// ========================================================
