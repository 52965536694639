export const AnnotSubject = 'SIGN.NET' // Add subject to identify annotation added by sign.net

export type StorageCanvas = {
  pageNum: number
  canvas: string
  pageWidth: number
  pageHeight: number
}

export type CanvasData = {
  canvas: fabric.Canvas
  pageWidth: number
  pageHeight: number
}

export type DocumentStatus = 'Upload' | 'Signing' | 'Viewing' | 'Completed'

export type DocumentAnnotation =
  | { kind: 'Name'; data: FreeText }
  | { kind: 'Date'; data: FreeText }
  | { kind: 'EditableText'; data: FreeText }
  | { kind: 'NonEditableText'; data: FreeText }
  | { kind: 'UnsignedSignature'; data: FreeText }
  | { kind: 'SignedSignature'; data: ImageStamp }
  | { kind: 'UnstampedStamp'; data: FreeText }
  | { kind: 'StampedStamp'; data: ImageStamp }
  | { kind: 'Email'; data: FreeText }
  | { kind: 'PlaceHolderCheckbox'; data: FreeText }
  | { kind: 'Checkbox'; data: CheckBox }
  | { kind: 'PlaceHolder'; data: FreeText }

export type TemplateAnnotation =
  | { kind: 'Name'; data: FreeText }
  | { kind: 'Date'; data: FreeText }
  | { kind: 'FreeText'; data: FreeText }
  | { kind: 'Signature'; data: ImageStamp }
  | { kind: 'TextField'; data: FreeText }
  | { kind: 'DateField'; data: FreeText }
  | { kind: 'SignatureField'; data: FreeText }
  | { kind: 'StampField'; data: FreeText }
  | { kind: 'NumberField'; data: FreeText }
  | { kind: 'NameField'; data: FreeText }
  | { kind: 'EmailField'; data: FreeText }
  | { kind: 'CheckboxField'; data: FreeText }

export type FillTemplateAnnotation =
  | { kind: 'Name'; data: FreeText }
  | { kind: 'Date'; data: FreeText }
  | { kind: 'FreeText'; data: FreeText }
  | { kind: 'Signature'; data: ImageStamp }
  | { kind: 'TextField'; data: FreeText }
  | { kind: 'DateField'; data: FreeText }
  | { kind: 'UnsignedSignatureField'; data: FreeText }
  | { kind: 'StampField'; data: FreeText }
  | { kind: 'SignedSignatureField'; data: ImageStamp }
  | { kind: 'NumberField'; data: FreeText }
  | { kind: 'EmailField'; data: FreeText }
  | { kind: 'NameField'; data: FreeText }
  | { kind: 'CheckboxField'; data: FreeText }

export type CheckBox = Readonly<{
  id: string
  style: Style
  content: string
  status: AnnotStatus
  color: null | Color
}>

export type FreeText = Readonly<{
  id: string
  content: string
  style: Style
  status: AnnotStatus
  color: null | Color
}>

export type ImageStamp = Readonly<{
  id: string
  dbId: string
  imageData: string
  style: Style
}>

export type AnnotStatus = 'Verified' | 'Unverified'

export type AnnotTemplateData = Readonly<{
  annotations: TemplateAnnotation[]
  owner: User
  base64FileData: string
}>

export type AnnotDocumentData = Readonly<{
  annotation: DocumentAnnotation
  user: User
}>

export type FilledDocumentAnnot = Readonly<{
  annotations: DocumentAnnotation[]
  user: User
}>

export type User = Readonly<{
  email: string
  firstName: string
  status: 'Registered' | 'Unregistered'
}>

export type Style = Readonly<{
  width: number
  height: number
  fontSize: string
  x: number
  y: number
  page: number
}>

export type Color = Readonly<{
  r: number
  g: number
  b: number
  a: number // opacity (float)
}>

export type Position = Readonly<{
  x: number
  y: number
  page: number
}>

export function impossibleCase(a: never): never {
  throw new Error(a)
}
