import { App } from '*.elm'
import * as PDFJS from 'pdfjs-dist/legacy/build/pdf.js'
import {
  StorageCanvas,
  FilledDocumentAnnot,
  DocumentAnnotation,
} from './lib/types'
import { initiatePage, deletePage } from './lib/webviewer'
import { fabric } from 'fabric'
import { drawAnnotation } from './documentAnnotation'

type NewDocument = Readonly<{
  data: string // Base64 data
  ext: 'pdf' | 'doc' | 'docx' | 'pptx'
  password: string | null
  fileName: string
  location: Location
}>
// const scale = 1.6

export type Location = 'Document' | 'MultipleDocument'

type Document = Readonly<{
  data: string // Base64 data
  ext: 'pdf' | 'doc' | 'docx' | 'pptx'
  password: string | null
  fileName: string
  filledAnnots: FilledDocumentAnnot[]
}>

export const loadNewDocument = (app: App) => async (
  docData: NewDocument,
): Promise<void> => {
  addEventListener('keydown', (event) => {
    if (event.key === 'Delete') {
      app.ports.returnDeleteSelectedAnnotMsg.send({})
    }
  })
  sessionStorage.clear()
  PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.min.js`
  const { data } = docData
  const loadingTask = PDFJS.getDocument(data)
  await loadingTask.promise.then(async function (pdf) {
    for (let i = 1; i <= pdf.numPages; i++) {
      await renderPage(i, pdf, app, [])
    }
    app.ports.returnMultipleDocLoadedMsg.send('Loaded')
  })
}

async function renderPage(
  num: number,
  pdfDoc: PDFJS.PDFDocumentProxy,
  app: App,
  annotations: DocumentAnnotation[],
) {
  pdfDoc.getPage(num).then(async function (page) {
    const canvas = await initiatePage(num)
    const context = canvas.getContext('2d')

    const pdfViewer = document
      .getElementById('pdfViewer')
      ?.getBoundingClientRect()

    // Render the page into the `<canvas>` element.
    if (context != null && pdfViewer != null) {
      const scale = canvas.width / pdfViewer.width
      // const scale = pdfViewer.width / canvas.width
      const viewport = page.getViewport({ scale })
      const scaleX = (pdfViewer.width * 0.9) / viewport.width
      const updatedViewport = page.getViewport({ scale: scaleX })
      canvas.height = updatedViewport.height
      const renderContext = {
        canvasContext: context,
        viewport: updatedViewport,
      }
      const renderTask = page.render(renderContext)
      // Wait for rendering to finish
      await renderTask.promise.then(() => {
        const pageViewPort = page.getViewport({ scale: 1 }) // Scale 1 give the original page dimensions
        const background = canvas.toDataURL('image/png')
        const fabricCanvas = new fabric.Canvas(canvas, { selection: false })
        fabricCanvas.setBackgroundImage(
          background,
          fabricCanvas.renderAll.bind(fabricCanvas),
        )
        fabricCanvas.setWidth(pdfViewer.width * 0.9)
        fabricCanvas.on('mouse:down', (data) => {
          const { offsetX, offsetY } = data.e
          const offsetXPercentage = offsetX / fabricCanvas.getWidth()
          const offsetYPercentage = offsetY / fabricCanvas.getHeight()

          const pdfOffsetX = pageViewPort.width * offsetXPercentage
          const pdfOffsetY = pageViewPort.height * offsetYPercentage
          const clickData = { x: pdfOffsetX, y: pdfOffsetY, page: num }
          app.ports.receiveMultipleDocumentClickAnnotData.send(clickData)
          // Trigger the click event
        })
        if (annotations.length !== 0) {
          annotations.forEach((annot) => {
            drawAnnotation(
              annot,
              {
                canvas: fabricCanvas,
                pageWidth: pageViewPort.width,
                pageHeight: pageViewPort.height,
              },
              'Upload',
              app,
            )
          })
        }
        // fabricCanvas.on('mouse:dblclick', () => {
        //   console.log('Double Click')
        // })

        // fabricCanvas.on('mouse:wheel', (event) => {
        //   if (event.e.ctrlKey) {
        //     const delta = event.e.deltaY
        //     let zoom = fabricCanvas.getZoom() * 0.999 ** delta

        //     if (zoom > 20) zoom = 20
        //     if (zoom < 0.01) zoom = 0.01
        //     fabricCanvas.zoomToPoint(
        //       { x: event.e.offsetX, y: event.e.offsetY },
        //       zoom,
        //     )
        //     event.e.preventDefault()
        //     event.e.stopPropagation()
        //     console.log(event.e.ctrlKey)
        //   }
        // })
        // fabricCanvas.on('object:modified', (event) => {
        //   console.log('object modified', event)
        // })
        // const rect = new fabric.Rect({
        //   left: 0,
        //   top: 0,
        //   width: 597.18 / 0.62,
        //   height: 50 / 0.62,
        //   fill: '#FF454F',
        //   opacity: 0.5,
        //   transparentCorners: true,
        //   borderColor: 'gray',
        //   cornerColor: 'gray',
        //   cornerSize: 5,
        //   data: { type: 'This is square', signer: 'Me' },
        //   // lockMovementX: true,
        //   // lockMovementY: true,
        //   // lockScalingX: true,
        //   // lockScalingY: true,
        // })
        // fabricCanvas.add(rect)
        // fabricCanvas.renderAll()
        // sessionStorage.setItem('test', fabricCanvas.toJSON())
        const fabricCanvasJson = fabricCanvas.toJSON([
          'left',
          'top',
          'width',
          'height',
          'fill',
          'opacity',
          'transparentCorners',
          'borderColor',
          'cornerColor',
          'cornerSize',
          'data',
          'lockMovementX',
          'lockMovementY',
          'lockScalingX',
          'lockScalingY',
        ])

        const storageCanvas: StorageCanvas = {
          pageNum: num,
          canvas: fabricCanvasJson,
          pageHeight: pageViewPort.height,
          pageWidth: pageViewPort.width,
        }
        sessionStorage.setItem(
          `fabricCanvas${num}`,
          JSON.stringify(storageCanvas),
        )
      })
    }
  })
}

export const switchDocument = (app: App) => async (
  docData: Document,
): Promise<void> => {
  const { data, filledAnnots } = docData
  addEventListener('keydown', (event) => {
    if (event.key === 'Delete') {
      app.ports.returnDeleteSelectedAnnotMsg.send({})
    }
  })
  sessionStorage.clear()
  PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.min.js`
  const loadingTask = PDFJS.getDocument(data)
  let annotList: DocumentAnnotation[] = []
  filledAnnots.forEach((annotData) => {
    annotList = [...annotList, ...annotData.annotations]
  })
  await loadingTask.promise.then(async function (pdf) {
    for (let i = 1; i <= pdf.numPages; i++) {
      deletePage(i)
      const annots = annotList.filter((annot) => annot.data.style.page === i)
      await renderPage(i, pdf, app, annots)
    }
  })
}
