import { App } from '*.elm'
import { FilledDocumentAnnot, User, DocumentAnnotation } from './lib/types'
import { StorageCanvas } from './lib/types'
import { initiatePage } from './lib/webviewer'
import * as PDFJS from 'pdfjs-dist/legacy/build/pdf.js'
import { fabric } from 'fabric'
import { drawAnnotation } from './documentAnnotation'

export type Document = Readonly<{
  id: string
  status: DocumentStatus
  fileUrl: string
  jwt: string
  owner: User
  currentUser: User
  filledAnnots: FilledDocumentAnnot[]
  password: string | null
  isExpired: boolean
}>

export type DocumentStatus = 'Signing' | 'Viewing' | 'Completed'
export const loadDocument = (app: App) => async (
  docData: Document,
): Promise<void> => {
  sessionStorage.clear()
  const { fileUrl, jwt, filledAnnots } = docData
  PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.min.js`
  const loadingTask = PDFJS.getDocument({
    url: fileUrl,
    httpHeaders: { authorization: 'Bearer ' + jwt },
    withCredentials: false,
  })
  let annotList: DocumentAnnotation[] = []
  filledAnnots.forEach((annotData) => {
    annotList = [...annotList, ...annotData.annotations]
  })
  await loadingTask.promise.then(async function (pdf) {
    for (let i = 1; i <= pdf.numPages; i++) {
      const annots = annotList.filter((annot) => annot.data.style.page === i)
      await renderPage(i, pdf, app, annots)
    }
    const pdfDoc = await pdf.getData()
    const base64 = Buffer.from(pdfDoc).toString('base64')
    app.ports.receiveDocumentLoaded.send(
      'data:application/pdf;base64,' + base64,
    )
  })
}

async function renderPage(
  num: number,
  pdfDoc: PDFJS.PDFDocumentProxy,
  app: App,
  annots: DocumentAnnotation[],
) {
  const page = await pdfDoc.getPage(num)
  const canvas = await initiatePage(num)
  const context = canvas.getContext('2d')

  const pdfViewer = document
    .getElementById('pdfViewer')
    ?.getBoundingClientRect()

  // Render the page into the `<canvas>` element.
  if (context != null && pdfViewer != null) {
    const pageViewPort = page.getViewport({ scale: 1 })
    const scaleX = (canvas.width * 0.9) / pageViewPort.width
    const updatedViewport = page.getViewport({ scale: scaleX })
    canvas.height = updatedViewport.height
    const renderContext = {
      canvasContext: context,
      viewport: updatedViewport,
    }
    await page.render(renderContext).promise
    // Wait for rendering to finish
    // await renderTask.promise.then(() => {
    const background = canvas.toDataURL('image/png')
    const fabricCanvas = new fabric.Canvas(canvas, { selection: false })
    fabricCanvas.setBackgroundImage(
      background,
      fabricCanvas.renderAll.bind(fabricCanvas),
      // { scaleX },
    )
    fabricCanvas.setWidth(pdfViewer.width * 0.9)
    annots.forEach((annot) => {
      drawAnnotation(
        annot,
        {
          canvas: fabricCanvas,
          pageWidth: pageViewPort.width,
          pageHeight: pageViewPort.height,
        },
        'Signing',
        app,
      )
    })

    const fabricCanvasJson = fabricCanvas.toJSON([
      'left',
      'top',
      'width',
      'height',
      'fill',
      'opacity',
      'transparentCorners',
      'borderColor',
      'cornerColor',
      'cornerSize',
      'data',
      'lockMovementX',
      'lockMovementY',
      'lockScalingX',
      'lockScalingY',
    ])

    const storageCanvas: StorageCanvas = {
      pageNum: num,
      canvas: fabricCanvasJson,
      pageHeight: pageViewPort.height,
      pageWidth: pageViewPort.width,
    }
    sessionStorage.setItem(`fabricCanvas${num}`, JSON.stringify(storageCanvas))
  }
}
