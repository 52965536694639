import { Style } from './types'
import { fabric } from 'fabric'

type EncodeAnnot = Readonly<{
  id: string
  content: string | null
  style: Style
  status: string
  kind: string
}>
export const initiatePage = async (
  pageNumber: number,
): Promise<HTMLCanvasElement> => {
  return new Promise((resolve) => {
    const interval = setInterval(async () => {
      const webviewerNode = document.getElementById('pdfViewer')
      if (webviewerNode) {
        const rect = webviewerNode.getBoundingClientRect()
        clearInterval(interval)
        const canvas = document.createElement('CANVAS') as HTMLCanvasElement
        canvas.height = rect.height
        canvas.width = rect.width
        canvas.id = 'canvas' + pageNumber
        const div = document.createElement('div')
        div.id = `canvasContainer${pageNumber}`
        // div.style.paddingLeft = '50%'
        div.style.marginBottom = '10px'
        div.appendChild(canvas)
        webviewerNode.appendChild(div)
        resolve(canvas)
      }
    }, 200)
  })
}

export function deletePage(pageNumber: number): void {
  const canvasElement = document.getElementById(`canvasContainer${pageNumber}`)
  if (canvasElement != null) {
    canvasElement.remove()
  }
}

export function unload(): void {
  // Removes any of the leftover pdf viewer elements
  const webviewerNode = document.getElementById('pdfViewer')
  try {
    if (webviewerNode) {
      webviewerNode.remove()
    }
    let pageNumber = 1
    let canvasElement = null
    do {
      canvasElement = document.getElementById(`canvasContainer${pageNumber}`)
      if (canvasElement != null) {
        canvasElement.remove()
        pageNumber++
      }
    } while (canvasElement != null)
  } catch (error) {
    console.error(error)
  }
}

export function encodeAnnot(
  annot: fabric.Object | null,
  pageNumber: number,
  fabricCanvas: fabric.Canvas,
  pageWidth: number,
  pageHeight: number,
  updatedText: string | null,
  numberOfLines: number,
): EncodeAnnot[] {
  if (
    annot == null ||
    annot.width == null ||
    annot.height == null ||
    annot.left == null ||
    annot.top == null ||
    annot.scaleY == null ||
    annot.scaleX == null
  ) {
    return []
  }
  const pixelWidthPercentage = annot.getScaledWidth() / fabricCanvas.getWidth()
  const pixelHeightPercentage =
    annot.getScaledHeight() / fabricCanvas.getHeight()
  const pixelXPercentage = annot.left / fabricCanvas.getWidth()
  const pixelYPercentage = annot.top / fabricCanvas.getHeight()
  // const pixelFontSizePercentage =
  //   parseInt(annot.data.fontSize) / fabricCanvas.getHeight()
  const pdfAnnotWidth = pageWidth * pixelWidthPercentage
  const pdfAnnotHeight = pageHeight * pixelHeightPercentage
  const pdfAnnotX = pageWidth * pixelXPercentage
  const pdfAnnotY = pageHeight * pixelYPercentage
  // const pdfFontSize = pageHeight * pixelFontSizePercentage
  return [
    {
      id: annot.data.id,
      content: updatedText === null ? annot.data.content : updatedText,
      style: {
        width: pdfAnnotWidth,
        height: pdfAnnotHeight,
        fontSize: getFontSizeForKind(
          annot.data.kind,
          pdfAnnotHeight,
          annot.data.fontSize.toString(),
          numberOfLines,
        ), //Need to add in the fontsize to ther data
        x: pdfAnnotX,
        y: pdfAnnotY,
        page: pageNumber,
      },
      status: annot.data.status,
      kind: annot.data.kind,
    },
  ]
}

function getFontSizeForKind(
  kind: string,
  annotHeight: number,
  fontSize: string,
  numberOfLines: number,
) {
  const calculatedFontSize =
    ((annotHeight * 0.7) / numberOfLines).toString() + 'pt'
  switch (kind) {
    case 'Name':
      return calculatedFontSize
    case 'Date':
      return calculatedFontSize
    case 'EditableText':
      return calculatedFontSize
    case 'NonEditableText':
      return calculatedFontSize
    case 'UnsignedSignature':
      return fontSize
    case 'SignedSignature':
      return fontSize
    case 'UnstampedStamp':
      return fontSize
    case 'StampedStamp':
      return fontSize
    case 'Email':
      return calculatedFontSize
    case 'PlaceHolderCheckbox':
      return fontSize
    case 'Checkbox':
      return fontSize
    case 'PlaceHolder':
      return fontSize
    default:
      return fontSize
  }
}
