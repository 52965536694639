{
    "location": {
      "login": {
        "signIn": "Sign In",     
        "emailAddress": "Email Address",
        "emailError": "Email is invalid",
        "password": "Password",
        "incorrectPassword": "Incorrect email or password. Click Forgot Password to reset your password or Sign up for an Account.",
        "accountSignUp": "Account Sign Up",
        "resetPassword": "Reset Password"
      },    
      "resetPasswordPage" : {
        "resetPassword": "Reset Password",
        "resetPasswordInstructions": "In order to trigger a password reset request, please provide us with the email account you used when signing up. A password reset email will then be sent with further instructions. ",
        "emailAddress": "Email Address",
        "backToLogin": "Back to Login",
        "emailSent": "Email Sent",
        "emailExistsText": "If an account exists that matches the email ",
        "emailExistsText2": "an email with instructions to activate the account will be sent to that account. Please ensure you keyed in the right email address.",
        "emailExistsText3": "If you still do not receive an email from us within the next five minutes, please also check your email spam folder and drop us an email at help@sign.net",
        "backToLoginPage": "Back to Login Page"
      },
      "signupPage" : {
        "businessPlan": "Business Plan",
        "personalPlan": "Personal Plan",
        "signUp": "Sign up",
        "forEveryone": "For everyone",
        "forBusiness": "For Business",
        "month": "month",
        "trialDays": "45 days trial",
        "cancelAnytime": "Cancel anytime",
        "signSecurely" : "Sign securely with anyone",
        "sendUnlimtedDocs": "Send unlimited documents",
        "sendPDFforms": "Send PDF Forms",
        "addOrganisationUser":"Add Organisation users"
      },
      "personalRegisterPage" : {
        "createFreeAccount": "Create Free Personal Account",
        "firstName": "First Name",
        "lastName": "Last Name",
        "emailAddress": "Email Address",
        "password": "Password",
        "confirmPassword": "Confirm Password",
        "referralCode":"Referral Code(Optional)",
        "agreeToTerms": "I agree to the terms of service and privacy policy published here.",
        "signUp": "Sign up",
        "returnToLogin": "Return to Login",
        "passwordRequirements": "Password must contain at least 8 characters, a lowercase letter, an uppercase letter and a number",
        "activateAccount": "Activate Account",
        "thankYouMsg": "Thank you for creating an account with Sign.net.",
        "emailActivateText": "Your sign up is not yet complete until you click on the email activation link sent to your registered email at .",
        "emailActivateText2": "If you still do not receive an email from us within the next two minutes, please check the spam, junk, or promotion sections of your email.",
        "needHelp": "If you face further issues, please drop us an email at",
        "needHelp2": "and we will get in touch with you.",
        "haveAnAccount": "Have an Account?",
        "digitalSigning": "Digital Signing",
        "easySafeAndFree": "Easy, Safe & Free for Everyone",
        "sendUnlimtedDocs": "Send and receive unlimited documents",
        "supportFormats": "Support for leading document formats",
        "identityVerification": "Identity Verification capabilities",
        "notariseDocs": "Notarise documents [blockchain powered]",
        "useForBusiness": "Using Sign.net for Your Business?",
        "suiteOfFeatures": "A Comprehensive Suite of Features to automate your workflows at scale.",
        "freeTrialText": "Try it out with a 45-Day Free Trial"
      },
      "businessRegister" : {
        "subdomain": "Subdomain",
        "companyName":"Company Name",
        "firstName": "First Name",
        "lastName": "Last Name",
        "emailAddress": "Email Address",
        "password": "Password",
        "confirmPassword": "Confirm Password",
        "referralCode":"Referral Code(Optional)",
        "agreeToTerms": "I agree to the terms of service and privacy policy published here.",
        "signUp": "Sign up",
        "returnToLogin": "Return to Login",
        "passwordRequirements": "Password must contain at least 8 characters, a lowercase letter, an uppercase letter and a number",
        "enhance": "Enhance",
        "customerExperience": "your customer experience",
        "closeDeals": "Close deals",
        "anytimeAnyehere": "anytime, anywhere",
        "modernise": "Modernise",
        "agreementProcess": "the agreement process",
        "effcientSecureWorkflow": "Efficient and secure workflows",
        "suiteOfFeatures": "A Comprehensive Suite of Features to Automate your Workflow Processes",
        "customDomainText": "Custom Domain for your Organisation",
        "organisationManagement": "Organisation Management",
        "userPermission": "User Permissions and Corporate Representation",
        "documentWorkflow": "Document Workflow Templates Automate ",
        "signingRequest": "Signing Requests through PDF Forms",
        "organisationRepo": "Organisation Document Repository",
        "granularPermission": "Granular permissions for documents"
      },
      "updateUploadDocErrors" : {
        "notAnnotationFound": "No annotation found for the document.",
        "unableToUpdate": " Unable to update the annotations.",
        "notInSigningOrder": "Unable to update the annotations,there are signers that are before your signing order have yet to sign.",
        "notPermission": "You do not have the permission to update the document annotations.",
        "passwordProvidedError": "Password provided is incorrect.",
        "unableToAppend": "Unable to append annotation(s) to the file.",
        "pdfNotEditable": "PDF is not editable.",
        "invalidPDFformat": "Invalid PDF. Please upload a valid PDF file instead.",
        "fileSizeExceeded": "Size of file cannot exceed 25MB.",
        "pdfDocOnly": "PDF document only (for sending).",
        "invalidData": "Invalid data provided. Please refresh and try again.",
        "passcodeRequirements": "Passcode entered can only be of 4 to 8 numeric characters."

      },
      "menuItem": {
        "newDocuments": "New Documents",     
        "newTemplate": "New Template",
        "dashboard": "Dashboard",
        "trustScore": "Trust Score", 
        "documents": "Documents",
        "viewNotarisedInformation": "View Notarised Information",
        "addressBook": "Address Book",
        "myAccount":"My Account",
        "myOrganisation": "My Organisation",
        "signNetRewardsPoints": "Sign.net Rewards Points",
        "gettingStarted": "Getting Started",
        "contactUs": "Contact Us",
        "faq": "FAQ"
      },
      "dashboard": {
        "uploadDocsTitle": "UPLOAD DOCUMENTS",
        "uploadDocsDescription": "Now you can upload multiple documents in PDF, DOCx, PPTx, XLSx files up to 25MB in size.",
        "uploadDocsStep1": "STEP 1: DRAG & DROP", 
        "uploadDocsStep1Part2": "or browse through your computer",
        "pendingDocuments": "PENDING DOCUMENTS",
        "sentDocuments": "SENT DOCUMENTS",
        "verification": "VERIFICATION",
        "systemRewardsPoints": "SIGN.NET REWARD POINT(S)",
        "incomingDocuments": "Incoming Documents",
        "outgoingDocuments": "Outgoing Documents",
        "document": "Document",
        "sender": "Sender",
        "recipient": "Recipient",
        "date": "Date",
        "viewAll": "VIEW ALL",
        "completedDocuments": "Completed Documents",
        "template": "Template",
        "submission": "Submission"
      },
      "uploadDocument": {
        "step1": "Step 1: ",
        "documentsUploaded": " Documents Uploaded",
        "step2": "Step 2: Add Recipients",
        "addNewRecipient": "Add New Recipient",
        "searchAddressBook": "Search Address Book",
        "firstName": "First Name",
        "lastName": "Last Name",
        "email": "Email",
        "add": "Add",
        "nextStep": "NEXT STEP",
        "viewer": "Viewer",
        "owner": "Owner",
        "sender": "Sender",
        "signer": "Signer",
        "emptyName": "Name cannot be empty",
        "invalidEmail": "Email is invalid",
        "clear": "clear",
        "onlySigner": "I AM THE ONLY SIGNER",
        "step3": "Step 3: Add Fields",
        "signatures": "Signatures",
        "stamps": "Stamps",
        "stampTooltipText": "Please upload an image file in .jpg, .jpeg, .png, .svg or .gif format, up to maximum size of 3MB. You may select only 1 stamp for each document.",
        "addToDocument": "Add to Document",
        "previousDocument": "Previous Document",
        "nextDocument": "Next Document",
        "signatureField": "Signature",
        "stampField": "Stamp",
        "nameField": "Name",
        "dateSigned": "Date Signed",
        "editableText": "Editable Text",
        "nonEditableText": "Non Editable Text",
        "emailField": "Email",
        "checkboxField": "Checkbox",
        "optionalSettings": "Optional Settings",
        "signOrder": "Signing Order",
        "dueDate": "Due Date",
        "additionalMessage": "Add a Message",
        "passcode": "Signing Request Passcode",
        "passcodeTooltipText": "Add another verification layer to your document with a Passcode. You will need to send this Passcode to your recipients via a separate device. Recipients will only be able to access this document with the Passcode.",
        "doNotSaveDocument": "Do Not Save Document",
        "notariseDocument": "Notarise Document",
        "algorandNetwork": "Algorand Network",
        "binanceNetwork": "Binance Network",
        "notariseTooltipText1": "This action costs ",
        "notariseTooltipText2": " SIGN points. You have ",
        "notariseTooltipText3": " SIGN points available. Toggle between the 2 networks in the account settings page. ",
        "sendButtonText": "Send",
        "sendDocument": "Send Document",
        "faqText": "Recipients will not be able to change the text inputs upon receiving the document. For more information, refer to the frequently asked questions",
        "termsOfUse": "Do note that our Service should only be used for certain categories of documents. For more information, refer to the terms of use",
        "confirm": "Confirm",
        "sendingYourDocs": "Sending your document",
        "success": "Success",
        "successText": "Your document has been sent out successfully.",
        "backToDashboard": "Back to Dashboard",
        "proceed": "proceed"
      },
      "verificationPage": {
        "verificationMethods": "Verification Methods",
        "verified": "VERIFIED",
        "notVerified": "NOT VERIFIED",
        "creditCard": "Credit Card",
        "creditCardVerificationText": "Verifying your credit card with us involves keying in a valid card so that we can authenticate it against our payment processor. Nothing will be charged to your card during this process.",
        "governmentId": "Government ID",
        "governmentIdVerificationText": "Verify your identity using a variety of official government issued documents such as passport, driver's license & identity card. Verification requests are directly handled by Passbase using their secure and GDPR compliant systems.",
        "mobilePhone": "Mobile Phone",
        "mobilePhoneVerificationText": "Mobile phone verification allows you to validate your phone number in our systems. We will send a unique number to your number listed in the user profile records in order to validate it.",
        "wallet": "Wallet",
        "creditCardDescriptionText": "Having your credit card details verified is part of your overall identity verification, and increases your trust score as it provides a basic level of certification that you have good financial standing to be extended credit by a financial institution.",
        "governmentIdDescriptionText": "We will redirect you to the Passbase verification portal, which will involve completing a liveness check in addition to taking a picture of your official government issued document. The system will automatically process your request and in most situations provide an immediate approval. We will also require access to a camera in order to take pictures of the government ID you are providing for the validation process.",
        "governmentIdDescriptionText2": "Only one concurrent government ID verification attempt is allowed per person. If you need help restarting the process, please reach out to help@sign.net or contact us here so we can reset it’s status.",
        "acknowledgementText": "I understand and am ready to proceed",
        "comingSoon": "Coming Soon",
        "mobilePhoneDescriptionText": "Ensure you have your mobile phone with you before you click verify. You wil receive an SMS to your registered mobile phone number which you will be asked to input to determine that the mobile number is yours.",
        "goToAccount": "Go to Account",
        "walletDescription": "You can verify your wallet on the Sign.net rewards points page through Metamask. Click the button below to be redirected to the rewards points page",
        "redirectToPointManagementPage": "Redirect to points management page",
        "verify": "verify"
      },
      "pointsManagement": {
        "headerText": "Get rewarded simply by using Sign.net",
        "headerDescription": "With your Sign.net reward points you can redeem for Sign.net subscription and add-ons, send them to your friends or transfer them out onto your personal external wallet.",
        "systemBalance": "System Balance",
        "systemBalanceTooltipText": "These are your Sign.net reward points on the Sign.net platform. Available tokens are immediately available for use while Pending tokens require a bit more time to be processed.",
        "externalWallet": "External Wallet",
        "externalWalletTooltipText": "Link an external Metamask wallet to your Sign.net account so that points can be withdrawn.",
        "togglePaymentNotarisationMethods": "Toggle Notarisation Network & Payment Method",
        "currentNetwork": "Current Network",
        "currentPaymentMethod": "Current payment method",
        "redeemForServices": "Redeem for Services",
        "redeemServicesTooltipText": "Redemption Vouchers are available for users in certain countries, depending on local KYC regulations.",
        "viewAllRewards": "View All Rewards",
        "addOrganisationUser": "Add Organisation User (1 Month)",
        "safepalWallet": "SafePal S1 Hardware Wallet",
        "amazonGiftCard": "$50 Amazon Gift Card",
        "sendPoints": "Send Points",
        "recipientEmail": "Recipient Email",
        "amount": "Amount",
        "remarks": "Remarks (optional)",
        "sendButton": "Send",
        "transactionHistory": "Transaction History (Last 5 Transactions)",
        "from": "From",
        "to": "To",
        "time": "Time",
        "status": "Status",
        "completedStatus": "Completed",
        "pendingStatus": "Pending",
        "inText": "In",
        "out": "Out",
        "viewAllButton": "View All",
        "pointsManagement": "Points Management",
        "findOutMore": "Find out more.",
        "available": "Available",
        "transactionHistoryHeader": "Transaction History"
      },
      "transactionDetailsPage": {
        "transactionDetails": "Transaction Details",
        "transactionId": "Transaction ID",
        "created": "Created",
        "approved": "Approved",
        "by": "By",
        "ipAddress": "IP Address",
        "remarks": "Remarks", 
        "returnToPointsActivities": "Return To Points Activities"
      },
      "viewDocumentPage": {
        "documentInformation": "Document Information",
        "documentId": "Document ID",
        "templateId": "Template ID",
        "documentTitle": "Document Title",
        "status": "Status",
        "pendingSignature": "Pending Your Signature",
        "documentCompleted": "Document Completed",
        "awaitingSignature": "Awaiting Signature",
        "expired": "Expired",
        "role": "Role",
        "verifiedWith": "Verified with",
        "notVerifiedWith": "Not verified with",
        "yourSignatures": "Your Signatures",
        "yourStamps": "Your Stamps",
        "downloadDocument": "Download Document",
        "downloadPartiallyCompletedDocument": "Download Partially Completed Document",
        "downloadDocumentText": "You are downloading a partially completed document that is pending signatures from recipients.",
        "downloadDocumentText2": "The recipients will still be able to sign and complete this document on Sign.net.",
        "proceed": "Proceed",
        "submit": "Submit",
        "viewCertificate": "View Certificate",
        "certificateOfCompletion": "Certificate Of Completion",
        "signerDetails": "Signer Details",
        "documentSummaryEvents": "Document Summary Events",
        "downloadCertificate": "Download Certificate",
        "notariseDocument": "Notarise Document",
        "notarisationCost": "This action costs ",
        "notarisationCost2": " SIGN points, are you sure you want to notarise this document?",
        "confirm": "Confirm",
        "backToDocuments": "Back To Documents",
        "notarisationStatus": "Notarisation Status",
        "documentChecksum": "Document Checksum",
        "timeOfNotarisation": "Time Of Notarisation",
        "signatureTimeStamping": "Signature Time Stamping (Algorand network)",
        "timeStamp": "Time Stamp",
        "activities": "Activities",
        "action": "Action",
        "sign": "Sign",
        "view": "View",
        "downloadCompletedDocument": "Download Completed Document",
        "downloadVerifiableCredentials": "Download Verifiable Credential",
        "sentReminder": "Sent Reminder", 
        "time": "Time",
        "deleteDocument": "Delete Document",
        "deleteDocumentText": "Do you want to delete this document?",
        "viewSigningEvents": "View Signing Events",
        "resendEmailNotification": "Resend Email Notification",
        "recipients": "Recipients",
        "signatureAndStamps": "Your Signature and Stamps",
        "helpDesk": "Helpdesk"
      },
      "listDocumentsPage": {
        "sender": "Sender",
        "signers": "Signers",
        "viewers": "Viewers",
        "dateCompleted": "Date Completed",
        "page": "Page",
        "dateSent": "Date Sent",
        "searchDocument": "Search Document"
      },
      "viewNotarisedInformation": {
        "viewNotarisedDocumentInformation": "View Notarised Document Information",
        "viewNotarisedInfoText": "View your notarised document information here by dropping in your completed PDF file that has been notarised with Algorand Blockchain.",
        "pleaseUploadFile": "Please upload your completed signed PDF to begin.",
        "dragAndDrop": "DRAG & DROP",
        "browseYourPC": "or browse through your computer",
        "verificationDone": "Verification Done",
        "transactionId": "Transaction Id",
        "checksum": "Checksum",
        "timeOfNotarisation": "Time of Notarisation",
        "docNotarisationDetails": "Document Notarisation Details"
      },
      "addressBookPage": {
        "addressBook": "Address Book",
        "pendingRequest": "Pending Request",
        "allContacts": "All Contacts",
        "addFriend": "Add Friend",
        "addNewContact": "Add New Contact",
        "contactEmailFieldLabel": "Please enter contact email",
        "sendRequestBtnText": "Send Request",
        "cancelBtn": "Cancel",
        "deleteContact": "Delete Contact",
        "deleteContactConfirmation": "Do you want to delete this contact?",
        "rowsPerPage": "Rows Per Page",
        "saveAlias": "Save new alias"
      },
      "accountSettingsPage": {
        "myAccount": "My Account",
        "accountSettings": "Account Settings",
        "changePicture": "Change Picture",
        "firstName":"First Name",
        "lastName":"Last Name",
        "phoneNumber": "Phone Number",
        "emailAddress": "Email Address",
        "nationality": "nationality",
        "address": "Address",
        "dateOfBirth": "Date Of Birth",
        "saveChanges": "Save Changes",
        "referralCode": "Referral Code",
        "personalAccount": "Personal Account",
        "businessAccount": "Business Account",
        "documentNotarisationNetwork": "Document Notarisation network",
        "currentChosenNetwork": "Current chosen network",
        "notariseByDefault": "Notarise documents by default",
        "notariseByDefaultTooltipText": "Documents will be notarise upon completion, this applies for algorand network and payment through system balance.",
        "paymentMethod": "Payment Method",
        "currentPaymentMethod": "Current payment Method",
        "signatures":"Signatures",
        "myPersonalStamps": "My Personal Stamps",
        "stampsTooltipText": "Please upload an image file in .jpg, .jpeg, .png, .svg or .gif format, up to maximum size of 3MB. You may select only 1 stamp for each document.",
        "changePassword": "Change Password",
        "currentPassword": "Current Password",
        "newPassword": "New Password",
        "confirmPassword": "Confirm Password",
        "apiKeysHeader": "API Keys",
        "personalApiKey": "Personal API Key",
        "addABusinessPlan" : "Add a Business Plan",
        "month": "month",
        "trialDays": "45 days trial",
        "cancelAnytime": "Cancel anytime",
        "signSecurely" : "Sign securely with anyone",
        "sendUnlimtedDocs": "Send unlimited documents",
        "sendPDFforms": "Send PDF Forms",
        "addOrganisationUser":"Add Organisation users",
        "businessPlan": "Business Plan"
      },
      "templateDashboard": {
        "sendFromTemplate": "Send From Template",
        "sendFromTemplateDescription": "Send out documents for signing from organisation templates",
        "selectTemplateFromDashboard": "STEP 1: Click to select a template",
        "selectTemplate": "Select a Template",
        "selectTemplateDescription": "Select a template below to send as document",
        "templateName": "Template Name",
        "createdBy": "Created By",
        "lastEdited": "Last Edited"
      },
      "fillTemplatePage": {
        "step2": "Step 2: Add Recipients",
        "owner": "Owner",
        "sender": "Sender",
        "next": "Next",
        "optionalSettings": "Optional Settings",
        "signOrder": "Signing Order",
        "dueDate": "Due Date",
        "additionalMessage": "Add a Message",
        "passcode": "Signing Request Passcode",
        "passcodeTooltipText": "Add another verification layer to your document with a Passcode. You will need to send this Passcode to your recipients via a separate device. Recipients will only be able to access this document with the Passcode.",
        "notariseDocument": "Notarise Document",
        "algorandNetwork": "Algorand Network",
        "binanceNetwork": "Binance Network",
        "notariseTooltipText1": "This action costs ",
        "notariseTooltipText2": " SIGN points. You have ",
        "notariseTooltipText3": " SIGN points available. Toggle between the 2 networks in the account settings page. ",
        "sendButtonText": "Send",
        "proceedToSend": "Proceed To Send",
        "sendTemplateTermsOfUse": "Do note that our Service should only be used for certain categories of documents. For more information, "
      },
      "uploadTemplatePage": {
        "newTemplate": "New Template",
        "numberOfTemplates": "Number of templates available in your organisation",
        "available": "Available",
        "used": "Used",
        "createNewTemplate": "Create New Template",
        "createTemplateDescription": "Templates are accessible by all users under your organisation. Users will be able to send out documents to Recipients from this template."
      },
      "createTemplatePage": {
        "templateTitle": "Step 1: Template Title",
        "title": "Title",
        "sharingUrlText": "Allow sharing of single-signer document with URL",
        "nextStep":"NEXT STEP",
        "addRole": "Step 2: Add Role",
        "addNewRole": "Add New Role",
        "addFields": "Step 3: Add Fields",
        "assignRecipientToRole": "Assign Recipient to Role",
        "assignRecipientToRoleTooltipText": "A recipient assigned to a role here will be added to all documents that are created from this template.",
        "assignRecipientToViewer": "Assign a viewer to template",
        "assignRecipientToViewerTooltipText": "A viewer will be added to all documents that are created from this template.",
        "newRole": "New Role",
        "addToDocument": "Add to Document",
        "signatureField": "Signature",
        "stampField": "Stamp",
        "dateSigned": "Date of Signing",
        "editableText": "Text",
        "nameField": "Name",
        "emailField": "Email",
        "checkboxField": "Checkbox",
        "optionalSettings": "Optional Settings",
        "signOrder": "Signing Order",
        "submit": "Submit",
        "uploadTemplate": "Upload Template",
        "uploadTemplateText": "*Do note that our Service should only be used for certain categories of documents. For more information, ",
        "referToTerms": "refer to the terms of use",
        "createButtonText": "Create",
        "templateUploadSuccess": "Your template has been uploaded successfully."
      },
      "myOrganisationPage": {
        "manageUsers": "Manage Users",
        "organisationReferralCode": "Organisation Referral Code",
        "shareLinkBusinessAccount": "Share Link to sign up for Business account",
        "shareLinkPersonalAccount": "Share Link to sign up for Personal account",
        "customizeLandingPage": "Customise Landing Page",
        "headerLogo": "Header Logo",
        "lessThan300KB": "Must be less than 300KB",
        "headerBackgroundColour": "Header Background Color",
        "headerBackgroundDescription": "The color of the top header",
        "bannerLogo": "Banner Logo",
        "lessThan500KB": "Must be less than 500KB",
        "bannerBackground": "Banner Background",
        "organisationStamps": "Organisation Stamps",
        "delete": "Delete",
        "businessProfile": "Business Profile",
        "companyName": "Company Name",
        "companySlogan": "Company Slogan",
        "companyDescription": "Company Description",
        "update": "Update"
      },
      "viewTemplatePage": {
        "nameOfTemplate": "Name of Template",
        "createdBy": "Created By",
        "dateCreated": "Date Created",
        "version": "Version",
        "lastEdited": "Last Edited",
        "lastViewed": "Last Viewed",
        "noOfDocumentsGeneratedFromTemplate": "No of documents generated from this template",
        "templateUrl": "Template URL",
        "downloadTemplateReport": "Download template report",
        "requestToFillTemplate": "Request to fill template",
        "fillTemplateRequest": "Fill Template Request",
        "fillTemplateRequestText": "This template is a single role template, please confirm if you are requesting to fill this template.",
        "confirm":"Confirm",
        "back": "Back",
        "viewTemplateActivity": "View template Activity",
        "deleteTemplate": "Delete template",
        "createDocumentFromTemplate" : "Create document from template",
        "backToDashboard":"Back to Dashboard",
        "close": "Close"
      }
    }
  }