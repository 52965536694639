import {
  AnnotTemplateData,
  //   TemplateAnnotation,
  StorageCanvas,
} from './lib/types'
import { fabric } from 'fabric'
import { App } from '*.elm'
import { drawAnnotation } from './templateAnnotation'
import { encodeAnnot } from './lib/webviewer'
import * as PDFJS from 'pdfjs-dist/legacy/build/pdf.js'

export function updateTemplate(
  app: App,
): (annotData: AnnotTemplateData) => void {
  return async function (annotData: AnnotTemplateData): Promise<void> {
    const { annotations, base64FileData } = annotData
    let pageNum = 1
    let storageString = sessionStorage.getItem(`fabricCanvas${pageNum}`)
    const canvasArray: StorageCanvas[] = []
    do {
      if (storageString != null) {
        canvasArray.push(JSON.parse(storageString))
        pageNum++
        storageString = sessionStorage.getItem(`fabricCanvas${pageNum}`)
      }
    } while (storageString != null)
    canvasArray.sort((a, b) => a.pageNum - b.pageNum)

    const loadingTask = PDFJS.getDocument(base64FileData)
    // Need to be able to search for all annotation or be able to delete annotation
    await loadingTask.promise.then(async function (pdf) {
      for (let i = 1; i <= pdf.numPages; i++) {
        const annotInPage = annotations.filter(
          (annot) => annot.data.style.page === i,
        )
        if (annotInPage.length > 0) {
          const canvas = canvasArray[i - 1]
          const { pageHeight, pageWidth } = canvas
          const page = await pdf.getPage(i)
          const canvasElement = await recreateCanvas(canvas, page)
          if (canvasElement) {
            canvasElement.clear()
            addListenerToCanvas(
              canvasElement,
              canvas.pageNum,
              app,
              pageWidth,
              pageHeight,
            )
            annotInPage.forEach((annot) => {
              drawAnnotation(
                annot,
                {
                  canvas: canvasElement,
                  pageHeight,
                  pageWidth,
                },
                app,
              )
            })
          }
        }
      }
    })
  }
}

function addListenerToCanvas(
  fabricCanvas: fabric.Canvas,
  pageNumber: number,
  app: App,
  pageWidth: number,
  pageHeight: number,
) {
  fabricCanvas.on('mouse:down', (data) => {
    const { offsetX, offsetY } = data.e

    // Note: 0.62 is the magic number determined by Heuristic evaluation
    // Actual pixel to points conversion is 0.75 but there is some offset involved
    const offsetXPercentage = offsetX / fabricCanvas.getWidth()
    const offsetYPercentage = offsetY / fabricCanvas.getHeight()

    const pdfOffsetX = pageWidth * offsetXPercentage
    const pdfOffsetY = pageHeight * offsetYPercentage
    const clickData = { x: pdfOffsetX, y: pdfOffsetY, page: pageNumber }
    app.ports.receiveTemplateClickAnnotData.send(clickData)
    // Trigger the click event
  })
  //   fabricCanvas.on('text:editing:exited', (event) => {
  //     if (event.target != null && event.target.type === 'textbox') {
  //       const encodeData = encodeAnnot(
  //         event.target,
  //         pageNumber,
  //         fabricCanvas,
  //         pageWidth,
  //         pageHeight,
  //         // @ts-ignore
  //         event.target.text,
  //       )
  //       app.ports.receiveTemplateAnnotData.send(encodeData)
  //     }
  //   })
  fabricCanvas.on('object:modified', (event) => {
    if (event.target == null) {
      return
    }
    const encodeData = encodeAnnot(
      event.target,
      pageNumber,
      fabricCanvas,
      pageWidth,
      pageHeight,
      null,
      // @ts-ignore
      event.target._textLines == null ? 1 : event.target._textLines.length,
    )
    app.ports.receiveTemplateDeselecedAnnot.send({})
    app.ports.receiveTemplateAnnotData.send(encodeData)
    // // @ts-ignore
    // switch (event.action) {
    //   case 'drag':
    //     return console.log('Drag')
    //   case 'scaleX':
    //     return console.log('scale x')
    //   case 'scaleY':
    //     return console.log('scale y')
    //   case 'scale':
    //     return console.log('scale')
    //   default:
    //     console.log('Other Actions')
    // }
  })

  fabricCanvas.on('selection:created', (event) => {
    // Add ts-ignore as the structure for the selection:created event is different
    app.ports.receiveSelectedTemplateAnnotClickData.send({
      page: pageNumber,
      // @ts-ignore
      id: event.selected[0].data.id,
    })
  })
  fabricCanvas.on('selection:updated', (event) => {
    app.ports.receiveSelectedTemplateAnnotClickData.send({
      page: pageNumber,
      // @ts-ignore
      id: event.selected[0].data.id,
    })
  })
  fabricCanvas.on('selection:cleared', () => {
    app.ports.receiveTemplateDeselecedAnnot.send({})
  })
}

async function recreateCanvas(
  canvasData: StorageCanvas,
  page: PDFJS.PDFPageProxy,
): Promise<fabric.Canvas | null> {
  const canvasContainerElement = document.getElementById(
    `canvasContainer${canvasData.pageNum}`,
  )
  const canvasElement = document.getElementById(
    `canvas${canvasData.pageNum}`,
  ) as HTMLCanvasElement
  const canvasChild = canvasContainerElement?.lastChild
  if (canvasContainerElement && canvasChild) {
    canvasContainerElement.removeChild(canvasChild)
  }
  const webviewerNode = document.getElementById('pdfViewer')
  if (webviewerNode == null) {
    return null
  }
  const rect = webviewerNode.getBoundingClientRect()
  const canvas = document.createElement('CANVAS') as HTMLCanvasElement
  canvas.height = canvasElement == null ? rect.height : canvasElement.height
  canvas.width = canvasElement == null ? rect.width : canvasElement.width
  canvas.id = `canvas${canvasData.pageNum}`
  const context = canvas.getContext('2d')
  if (context == null) {
    return null
  }
  canvasContainerElement?.appendChild(canvas)
  const pageViewPort = page.getViewport({ scale: 1 })
  const scaleX = (rect.width * 0.9) / pageViewPort.width
  const updatedViewport = page.getViewport({ scale: scaleX })
  canvas.height = updatedViewport.height
  const renderContext = {
    canvasContext: context,
    viewport: updatedViewport,
  }
  await page.render(renderContext).promise
  const background = canvas.toDataURL('image/png')
  const canvasFabric = new fabric.Canvas(canvas)
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  canvasFabric.loadFromJSON(canvasData.canvas, () => {})
  canvasFabric.setBackgroundImage(
    background,
    canvasFabric.renderAll.bind(canvasFabric),
  )
  canvasFabric.setWidth(rect.width * 0.9)
  return canvasFabric
}
