{
    "location": {
      "login": {
        "signIn": "Sign In",     
        "emailAddress": "Email Address",
        "emailError": "Email is invalid",
        "password": "Password",
        "incorrectPassword": "Incorrect email or password. Click Forgot Password to reset your password or Sign up for an Account.",
        "accountSignUp": "Account Sign Up",
        "resetPassword": "Reset Password"
      },    
      "resetPasswordPage" : {
        "resetPassword": "Reset Password",
        "resetPasswordInstructions": "In order to trigger a password reset request, please provide us with the email account you used when signing up. A password reset email will then be sent with further instructions. ",
        "emailAddress": "Email Address",
        "backToLogin": "Back to Login",
        "emailSent": "Email Sent",
        "emailExistsText": "If an account exists that matches the email ",
        "emailExistsText2": "an email with instructions to activate the account will be sent to that account. Please ensure you keyed in the right email address.",
        "emailExistsText3": "If you still do not receive an email from us within the next five minutes, please also check your email spam folder and drop us an email at help@sign.net",
        "backToLoginPage": "Back to Login Page"
      },
      "signupPage" : {
        "businessPlan": "Business Plan",
        "personalPlan": "Personal Plan",
        "signUp": "Sign up",
        "forEveryone": "For everyone",
        "forBusiness": "For Business",
        "month": "month",
        "trialDays": "45 days trial",
        "cancelAnytime": "Cancel anytime",
        "signSecurely" : "Sign securely with anyone",
        "sendUnlimtedDocs": "Send unlimited documents",
        "sendPDFforms": "Send PDF Forms",
        "addOrganisationUser":"Add Organisation users"
      },
      "personalRegisterPage" : {
        "createFreeAccount": "Create Free Personal Account",
        "firstName": "First Name",
        "lastName": "Last Name",
        "emailAddress": "Email Address",
        "password": "Password",
        "confirmPassword": "Confirm Password",
        "referralCode":"Referral Code(Optional)",
        "agreeToTerms": "I agree to the terms of service and privacy policy published here.",
        "signUp": "Sign up",
        "returnToLogin": "Return to Login",
        "passwordRequirements": "Password must contain at least 8 characters, a lowercase letter, an uppercase letter and a number",
        "activateAccount": "Activate Account",
        "thankYouMsg": "Thank you for creating an account with Sign.net.",
        "emailActivateText": "Your sign up is not yet complete until you click on the email activation link sent to your registered email at .",
        "emailActivateText2": "If you still do not receive an email from us within the next two minutes, please check the spam, junk, or promotion sections of your email.",
        "needHelp": "If you face further issues, please drop us an email at",
        "needHelp2": "and we will get in touch with you.",
        "haveAnAccount": "Have an Account?",
        "digitalSigning": "Digital Signing",
        "easySafeAndFree": "Easy, Safe & Free for Everyone",
        "sendUnlimtedDocs": "Send and receive unlimited documents",
        "supportFormats": "Support for leading document formats",
        "identityVerification": "Identity Verification capabilities",
        "notariseDocs": "Notarise documents [blockchain powered]",
        "useForBusiness": "Using Sign.net for Your Business?",
        "suiteOfFeatures": "A Comprehensive Suite of Features to automate your workflows at scale.",
        "freeTrialText": "Try it out with a 45-Day Free Trial"
      },
      "businessRegister" : {
        "subdomain": "Subdomain",
        "companyName":"Company Name",
        "firstName": "First Name",
        "lastName": "Last Name",
        "emailAddress": "Email Address",
        "password": "Password",
        "confirmPassword": "Confirm Password",
        "referralCode":"Referral Code(Optional)",
        "agreeToTerms": "I agree to the terms of service and privacy policy published here.",
        "signUp": "Sign up",
        "returnToLogin": "Return to Login",
        "passwordRequirements": "Password must contain at least 8 characters, a lowercase letter, an uppercase letter and a number",
        "enhance": "Enhance",
        "customerExperience": "your customer experience",
        "closeDeals": "Close deals",
        "anytimeAnyehere": "anytime, anywhere",
        "modernise": "Modernise",
        "agreementProcess": "the agreement process",
        "effcientSecureWorkflow": "Efficient and secure workflows",
        "suiteOfFeatures": "A Comprehensive Suite of Features to Automate your Workflow Processes",
        "customDomainText": "Custom Domain for your Organisation",
        "organisationManagement": "Organisation Management",
        "userPermission": "User Permissions and Corporate Representation",
        "documentWorkflow": "Document Workflow Templates Automate ",
        "signingRequest": "Signing Requests through PDF Forms",
        "organisationRepo": "Organisation Document Repository",
        "granularPermission": "Granular permissions for documents"
      },
      "updateUploadDocErrors" : {
        "notAnnotationFound": "No annotation found for the document.",
        "unableToUpdate": " Unable to update the annotations.",
        "notInSigningOrder": "Unable to update the annotations,there are signers that are before your signing order have yet to sign.",
        "notPermission": "You do not have the permission to update the document annotations.",
        "passwordProvidedError": "Password provided is incorrect.",
        "unableToAppend": "Unable to append annotation(s) to the file.",
        "pdfNotEditable": "PDF is not editable.",
        "invalidPDFformat": "Invalid PDF. Please upload a valid PDF file instead.",
        "fileSizeExceeded": "Size of file cannot exceed 25MB.",
        "pdfDocOnly": "PDF document only (for sending).",
        "invalidData": "Invalid data provided. Please refresh and try again.",
        "passcodeRequirements": "Passcode entered can only be of 4 to 8 numeric characters."

      },
      "menuItem": {
        "newDocuments": "Nieuwe documenten",     
        "newTemplate": "Nieuwe sjabloon",
        "dashboard": "Dashboard",
        "trustScore": "Trust Score", 
        "documents": "Documenten",
        "viewNotarisedInformation": "Bekijk notarisatie-informatie",
        "addressBook": "Adresboek",
        "myAccount":"Mijn rekening",
        "myOrganisation": "Mijn organisatie",
        "signNetRewardsPoints": "Sign.net Belonings Punten",
        "gettingStarted": "Aan de slag",
        "contactUs": "Neem contact met ons op",
        "faq": "FAQ"
      },
      "dashboard": {
        "uploadDocsTitle": "DOCUMENTEN UPLOADEN",
        "uploadDocsDescription": "U kunt nu meerdere documenten uploaden in PDF-, DOCx-, PPTx-, XLSx-bestanden tot 25 MB groot.",
        "uploadDocsStep1": "STAP 1: DRAG & DROP", 
        "uploadDocsStep1Part2": "of blader door uw computer",
        "pendingDocuments": "WACHTENDE DOCUMENTEN",
        "sentDocuments": "VERZONDEN DOCUMENTEN",
        "verification": "VERIFICATIE",
        "systemRewardsPoints": "SIGN.NET BELONINGS PUNTEN",
        "incomingDocuments": "Inkomende Documenten",
        "outgoingDocuments": "Uitgaande Documenten",
        "document": "Document",
        "sender": "Afzender",
        "recipient": "Ontvanger",
        "date": "Datum",
        "viewAll": "BEKIJK ALLES",
        "completedDocuments": "Voltooide Documenten",
        "template": "Sjabloon",
        "submission": "Dwang"
      },
      "uploadDocument": {
        "step1": "Stap 1: ",
        "documentsUploaded": " documenten geüpload",
        "step2": "Stap 2: Ontvangers toevoegen",
        "addNewRecipient": "Nieuwe ontvanger toevoegen",
        "searchAddressBook": "Zoeken in het adresboek",
        "firstName": "Voornaam",
        "lastName": "Achternaam",
        "email": "E-mail",
        "add": "Toevoegen",
        "nextStep": "VOLGENDE STAP",
        "viewer": "Viewer",
        "owner": "Eigenaar",
        "sender": "Afzender",
        "signer": "Ondertekenaar",
        "emptyName": "Naam kan niet leeg zijn",
        "invalidEmail": "E-mail is ongeldig",
        "clear": "wissen",
        "onlySigner": "IK BEN DE ENIGE ONDERTEKENAAR",
        "step3": "Stap 3: Velden toevoegen",
        "signatures": "Handtekeningen",
        "stamps": "Stempels",
        "stampTooltipText": "Voeg alstublieft een afbeeldingsbestand toe in .jpg, .jpeg, .png, .svg of .gif formaat, met een maximale grootte van 3MB. U kunt slechts 1 stempel per document selecteren.",
        "addToDocument": "Toevoegen aan document",
        "previousDocument": "vorig document",
        "nextDocument": "Volgend document",
        "signatureField": "Handtekening",
        "stampField": "Stempel",
        "nameField": "Naam",
        "dateSigned": "Datum ondertekend",
        "editableText": "Bewerkbare tekst",
        "nonEditableText": "Onbewerkbare tekst",
        "emailField": "E-mail",
        "checkboxField": "Selectievakje",
        "optionalSettings": "Optionele instellingen",
        "signOrder": "Ondertekeningsvolgorde",
        "dueDate": "Vervaldatum",
        "additionalMessage": "Voeg een bericht toe",
        "passcode": "Ondertekeningsaanvraag wachtwoord",
        "passcodeTooltipText": "Voeg een extra verificatielaag toe aan uw document met een wachtwoord. U moet dit wachtwoord via een apart apparaat naar uw ontvangers sturen. Ontvangers kunnen alleen toegang krijgen tot dit document met het wachtwoord.",
        "doNotSaveDocument": "Document niet opslaan",
        "notariseDocument": "Notarieer document",
        "algorandNetwork": "Algorand-netwerk",
        "binanceNetwork": "Binance-netwerk",
        "notariseTooltipText1": "Deze actie kost ",
        "notariseTooltipText2": " SIGN-punten. U heeft",
        "notariseTooltipText3": " SIGN-punten beschikbaar. Schakel tussen de 2 netwerken in de accountinstellingenpagina. ",
        "sendButtonText": "Verstuur",
        "sendDocument": "Document verzenden",
        "faqText": "Ontvangers kunnen de tekstinvoer niet wijzigen bij ontvangst van het document. Voor meer informatie, raadpleeg de veelgestelde vragen",
        "termsOfUse": "Houd er rekening mee dat onze service alleen gebruikt kunnen worden voor bepaalde categorieën van documenten. Voor meer informatie, raadpleeg de gebruiksvoorwaarden",
        "confirm": "Bevestigen",
        "sendingYourDocs": "Uw document verzenden",
        "success": "Succes",
        "successText": "Uw document is succesvol verzonden.",
        "backToDashboard": "Terug naar dashboard",
        "proceed": "Doorgaan"
      },
      "verificationPage": {
        "verificationMethods": "Verificatiemethoden",
        "verified": "GEVERIFIEERD",
        "notVerified": "NIET GEVERIFIEERD",
        "creditCard": "Creditcard",
        "creditCardVerificationText": "Het verifiëren van uw creditcard bij ons houdt in dat u een geldige kaart invoert zodat we deze bij onze betalingsprocessor kunnen authentificeren. Er zal tijdens dit proces niets in rekening worden gebracht.",
        "governmentId": "Overheids-ID",
        "governmentIdVerificationText": "Verifieer uw identiteit met behulp van verschillende officiële documenten uitgegeven door de overheid, zoals paspoort, rijbewijs en identiteitskaart. Verificatieverzoeken worden rechtstreeks afgehandeld door Passbase met behulp van hun veilige en GDPR-conforme systemen.",
        "mobilePhone": "Mobiele telefoon",
        "mobilePhoneVerificationText": "Mobiele telefoonverificatie stelt u in staat om uw telefoonnummer in ons systeem te valideren. We zullen een uniek nummer naar uw nummer sturen dat vermeld staat in de gebruikersprofielgegevens om het te valideren.",
        "wallet": "Portemonnee",
        "creditCardDescriptionText": "Het verifiëren van uw creditcardgegevens maakt deel uit van uw algemene identiteitsverificatie en verhoogt uw vertrouwensscore omdat het een basisniveau van certificering biedt dat u een goede financiële positie heeft om krediet te krijgen van een financiële instelling.",
        "governmentIdDescriptionText": "We zullen u doorverwijzen naar het verificatieportaal van Passbase, waarbij het invullen van een liveness check in combinatie met het maken van een foto van uw officiële door de overheid uitgegeven document vereist is. Het systeem zal uw verzoek automatisch verwerken en in de meeste situaties onmiddellijk goedkeuren. We hebben ook toegang tot een camera nodig om foto's te maken van het door de overheid uitgegeven ID dat u verstrekt voor het validatieproces.",
        "governmentIdDescriptionText2": "Er is slechts één gelijktijdige verificatiepoging van een overheids-ID toegestaan per persoon mogelijk. Als u hulp nodig heeft bij het opnieuw opstarten van het proces, neem dan contact op met help@sign.net of neem hier contact met ons op, zodat we de status kunnen resetten.",
        "acknowledgementText": "Ik begrijp het en ben klaar om verder te gaan.",
        "comingSoon": "Binnenkort beschikbaar",
        "mobilePhoneDescriptionText": "Zorg ervoor dat u uw mobiele telefoon bij u heeft voordat u op verifiëren klikt. U ontvangt een SMS op uw geregistreerde mobiele telefoonnummer, zodat u kunt bewijzen dat het mobiele nummer van u is.",
        "goToAccount": "Ga naar account",
        "walletDescription": "U kunt uw portemonnee verifiëren op de pagina voor beloningspunten van Sign.net via Metamask. Klik op de onderstaande knop om te worden doorverwezen naar de pagina voor beloningspunten.",
        "redirectToPointManagementPage": "Doorverwijzen naar pagina voor puntenbeheer",
        "verify": "verifiëren"
      },
      "pointsManagement": {
        "headerText": "Word beloond door simpelweg Sign.net te gebruiken",
        "headerDescription": "Met uw Sign.net beloningspunten kunt u zich abonneren op Sign.net en add-ons, ze naar uw vrienden sturen of overmaken naar uw persoonlijke externe portemonnee.",
        "systemBalance": "Systeemsaldo",
        "systemBalanceTooltipText": "Dit zijn je Sign.net beloningspunten op het Sign.net platform. Beschikbare tokens zijn direct beschikbaar voor gebruik, terwijl Wachtende tokens wat meer tijd nodig hebben om verwerkt te worden.",
        "externalWallet": "Externe Portemonnee",
        "externalWalletTooltipText": "Koppel een externe Metamask-portemonnee aan je Sign.net-account zodat punten kunnen worden opgenomen.",
        "togglePaymentNotarisationMethods": "Notarisatienetwerk & Betalingsmethode in- en uitschakelen",
        "currentNetwork": "Huidig netwerk",
        "currentPaymentMethod": "Huidige betalingsmethode",
        "redeemForServices": "Inwisselen voor Diensten",
        "redeemServicesTooltipText": "Inwisselingsvouchers zijn beschikbaar voor gebruikers in bepaalde landen, afhankelijk van lokale KYC-regelgeving.",
        "viewAllRewards": "Bekijk alle beloningen",
        "addOrganisationUser": "Organisatiegebruiker toevoegen (1 maand)",
        "safepalWallet": "SafePal S1 hardwareportemonnee",
        "amazonGiftCard": "$50 Amazon cadeaubon",
        "sendPoints": "Punten verzenden",
        "recipientEmail": "Ontvanger e-mail",
        "amount": "Bedrag",
        "remarks": "Opmerkingen (optional)",
        "sendButton": "Verstuur",
        "transactionHistory": "Transactiegeschiedenis (Laatste 5 transacties)",
        "from": "Van",
        "to": "Naar",
        "time": "Tijd",
        "status": "Status",
        "completedStatus": "Voltooid",
        "pendingStatus": "In afwachting",
        "inText": "In",
        "out": "Uit",
        "viewAllButton": "Bekijk Alles",
        "pointsManagement": "Puntenbeheer",
        "findOutMore": "Meer informatie vinden.",
        "available": "Beschikbaar",
        "transactionHistoryHeader": "Transactiegeschiedenis"
      },
      "transactionDetailsPage": {
        "transactionDetails": "Transactiedetails",
        "transactionId": "Transactie-ID",
        "created": "Gemaakt",
        "approved": "Goedgekeurd",
        "by": "DoorBy",
        "ipAddress": "IP-adres",
        "remarks": "Opmerkingen", 
        "returnToPointsActivities": "Keer terug naar puntenactiviteiten"
      },
      "viewDocumentPage": {
        "documentInformation": "Documentinformatie",
        "documentId": "Document-ID",
        "templateId": "Sjabloon-ID",
        "documentTitle": "Documenttitel",
        "status": "Status",
        "pendingSignature": "Wachtend op jouw handtekening",
        "documentCompleted": "Document voltooid",
        "awaitingSignature": "In afwachting van handtekening",
        "expired": "Verlopen",
        "role": "Rol",
        "verifiedWith": "Geverifieerd met",
        "notVerifiedWith": "Niet geverifieerd met",
        "yourSignatures": "Jouw handtekeningen",
        "yourStamps": "Jouw stempels",
        "downloadDocument": "Document downloaden",
        "downloadPartiallyCompletedDocument": "Gedeeltelijk voltooid document downloaden",
        "downloadDocumentText": "Je bent bezig met het downloaden van een gedeeltelijk voltooid document dat wacht op handtekeningen van ontvangers.",
        "downloadDocumentText2": "Ontvangers kunnen dit document nog steeds op Sign.net ondertekenen en voltooien.",
        "proceed": "Doorgaan",
        "submit": "Verzenden",
        "viewCertificate": "Certificaat bekijken",
        "certificateOfCompletion": "Certificaat van voltooiing",
        "signerDetails": "Ondertekenaarsgegevens",
        "documentSummaryEvents": "Document samenvatting Evenementen",
        "downloadCertificate": "Certificaat downloaden",
        "notariseDocument": "Document notariseren",
        "notarisationCost": "Deze actie kost ",
        "notarisationCost2": " SIGN-punten, weet je zeker dat je dit document wilt notariseren?",
        "confirm": "Bevestigen",
        "backToDocuments": "Terug naar documenten",
        "notarisationStatus": "Notarisatiestatus",
        "documentChecksum": "Documentchecksum",
        "timeOfNotarisation": "Tijdstip van notarisatie",
        "signatureTimeStamping": "Tijdstempel handtekening (Algorand-netwerk)",
        "timeStamp": "Tijdstempel",
        "activities": "Activiteiten",
        "action": "Actie",
        "sign": "Ondertekenen",
        "view": "Bekijken",
        "downloadCompletedDocument": "Voltooid document downloaden",
        "downloadVerifiableCredentials": "Verifieerbaar bewijs downloaden",
        "sentReminder": "Herinnering verzonden", 
        "time": "Tijd",
        "deleteDocument": "Document verwijderen",
        "deleteDocumentText": "Wil je dit document verwijderen?",
        "viewSigningEvents": "Bekijk ondertekenverzoek",
        "resendEmailNotification": "Opnieuw verzenden e-mailmelding",
        "recipients": "Ontvangers",
        "signatureAndStamps": "Uw handtekening en stempels",
        "helpDesk": "Helpdesk"
      },
      "listDocumentsPage": {
        "sender": "Afzender",
        "signers": "Ondertekenaars",
        "viewers": "Viewers",
        "dateCompleted": "Datum voltooid",
        "page": "Pagina",
        "dateSent": "Datum verzonden",
        "searchDocument": "Zoek document"
      },
      "viewNotarisedInformation": {
        "viewNotarisedDocumentInformation": "Bekijk genotariseerde documentinformatie",
        "viewNotarisedInfoText": "Bekijk hier de informatie van uw notariële document door uw voltooide PDF-bestand dat is genotariseerd met Algorand Blockchain hier in te laten vallen.",
        "pleaseUploadFile": "Upload alstublieft uw ondertekende PDF-bestand om te beginnen.",
        "dragAndDrop": "DRAG & DROP",
        "browseYourPC": "of blader door uw computer",
        "verificationDone": "Verificatie Voltooid",
        "transactionId": "Transactie-ID",
        "checksum": "Checksum",
        "timeOfNotarisation": "Tijd van Notariëring",
        "docNotarisationDetails": "Document Notariële Gegevens"
      },
      "addressBookPage": {
        "addressBook": "Adresboek",
        "pendingRequest": "In behandeling",
        "allContacts": "Alle Contacten",
        "addFriend": "Vriend Toevoegen",
        "addNewContact": "Nieuwe Contacten Toevoegen",
        "contactEmailFieldLabel": "Voer e-mailadres contactpersoon in",
        "sendRequestBtnText": "Verzoek verzenden",
        "cancelBtn": "Annuleren",
        "deleteContact": "Contact verwijderen",
        "deleteContactConfirmation": "Wilt u dit contact verwijderen?",
        "rowsPerPage": "Rijen per pagina",
        "saveAlias": "Nieuwe alias opslaan"
      },
      "accountSettingsPage": {
        "myAccount": "Mijn account",
        "accountSettings": "Accountinstellingen",
        "changePicture": "Foto wijzigen",
        "firstName":"Voornaam",
        "lastName":"Achternaam",
        "phoneNumber": "Telefoonnummer",
        "emailAddress": "E-mailadres ",
        "nationality": "Nationaliteit",
        "address": "Adres",
        "dateOfBirth": "Geboortedatum",
        "saveChanges": "wijzigingen opslaan",
        "referralCode": "Verwijzingscode",
        "personalAccount": "Persoonlijk Account",
        "businessAccount": "Zakelijk Account",
        "documentNotarisationNetwork": "Documentnotarisatie-netwerk",
        "currentChosenNetwork": "Huidig gekozen netwerk",
        "notariseByDefault": "Documenten standaard notariseren",
        "notariseByDefaultTooltipText": "Documenten worden genotariseerd bij voltooiing, dit geldt voor het Algorand-netwerk en betaling via het systeemsaldo.",
        "paymentMethod": "Betalingsmethode",
        "currentPaymentMethod": "Huidige betaalmethode",
        "signatures":"Handtekeningen",
        "myPersonalStamps": "Mijn persoonlijke stempels",
        "stampsTooltipText": "Voeg alstublieft een afbeeldingsbestand toe in .jpg, .jpeg, .png, .svg of .gif formaat, met een maximale grootte van 3MB. U kunt slechts 1 stempel per document selecteren.",
        "changePassword": "Wachtwoord wijzigen",
        "currentPassword": "Huidig wachtwoord",
        "newPassword": "Nieuw wachtwoord",
        "confirmPassword": "Wachtwoord bevestigen",
        "apiKeysHeader": "API-sleutels",
        "personalApiKey": "Persoonlijke API-sleutel",
        "addABusinessPlan" : "Bedrijfsplan toevoegen",
        "month": "maand",
        "trialDays": "45 dagen proefperiode",
        "cancelAnytime": "Op elk moment annuleren",
        "signSecurely" : "Veilig ondertekenen met iedereen",
        "sendUnlimtedDocs": "Onbeperkt documenten verzenden",
        "sendPDFforms": "PDF-formulieren verzendens",
        "addOrganisationUser":"Gebruikers toevoegen aan organisatie",
        "businessPlan": "Bedrijfsplan"
      },
      "templateDashboard": {
        "sendFromTemplate": "Verzenden vanuit sjabloon",
        "sendFromTemplateDescription": " Verzend documenten voor ondertekening vanuit organisatie",
        "selectTemplateFromDashboard": "STAP 1: Klik om een ​​sjabloon te selecteren",
        "selectTemplate": "Selecteer een sjabloon",
        "selectTemplateDescription": "Selecteer hieronder een sjabloon om als document te verzenden",
        "templateName": "Sjabloonnaam",
        "createdBy": "Aangemaakt door",
        "lastEdited": "Laatst bewerkt"
      },
      "fillTemplatePage": {
        "step2": "Stap 2: Ontvangers toevoegen",
        "owner": "Eigenaar",
        "sender": "Afzender",
        "next": "Volgende",
        "optionalSettings": "Optionele instellingen",
        "signOrder": "Ondertekeningsvolgorde",
        "dueDate": "Vervaldatum",
        "additionalMessage": "Voeg een bericht toe",
        "passcode": "Ondertekeningsaanvraag wachtwoord",
        "passcodeTooltipText": "Voeg een extra verificatielaag toe aan uw document met een wachtwoord. U moet dit wachtwoord via een apart apparaat naar uw ontvangers sturen. Ontvangers kunnen alleen toegang krijgen tot dit document met het wachtwoord.",
        "notariseDocument": "Notarieer document",
        "algorandNetwork": "Algorand-netwerk",
        "binanceNetwork": "Binance-netwerk",
        "notariseTooltipText1": "Deze actie kost ",
        "notariseTooltipText2": " SIGN-punten. U heeft",
        "notariseTooltipText3": " SIGN-punten beschikbaar. Schakel tussen de 2 netwerken in de accountinstellingenpagina. ",
        "sendButtonText": "Verstuur",
        "proceedToSend": "Doorgaan met verzenden",
        "sendTemplateTermsOfUse": "Houd er rekening mee dat onze service alleen moet worden gebruikt voor bepaalde categorieën documenten. Voor meer informatie, "
      },
      "uploadTemplatePage": {
        "newTemplate": "Nieuwe sjabloon",
        "numberOfTemplates": "Aantal sjablonen beschikbaar in uw organisatie",
        "available": "Beschikbaar",
        "used": "Gebruikt",
        "createNewTemplate": "Nieuw sjabloon maken",
        "createTemplateDescription": "Sjablonen zijn toegankelijk voor alle gebruikers onder uw organisatie. Gebruikers kunnen documenten vanuit dit sjabloon naar ontvangers verzenden."
      },
      "createTemplatePage": {
        "templateTitle": "Stap 1: Sjabloontitel",
        "title": "Titel",
        "sharingUrlText": "Delen van een document met één ondertekenaar met URL toestaan",
        "nextStep":"Volgende stap",
        "addRole": "Stap 2: Rol toevoegen",
        "addNewRole": "nieuwe rol toevoegen",
        "addFields": "Stap 3: Velden toevoegen",
        "assignRecipientToRole": "Toewijzen van een kijker aan sjabloon",
        "assignRecipientToRoleTooltipText": "Een ontvanger die hier aan een rol is toegewezen, wordt toegevoegd aan alle documenten die vanuit dit sjabloon zijn gemaakt.",
        "assignRecipientToViewer": "Toewijzen van een kijker aan sjabloon",
        "assignRecipientToViewerTooltipText": "Een kijker zal worden toegevoegd aan alle documenten die vanuit dit sjabloon worden gemaakt.",
        "newRole": "Nieuwe rol",
        "addToDocument": "Toevoegen aan document",
        "signatureField": "Handtekening",
        "stampField": "Stempel",
        "dateSigned": "Datum ondertekend",
        "editableText": "Tekst",
        "nameField": "Naam",
        "emailField": "E-mail",
        "checkboxField": "Selectievakje",
        "optionalSettings": "Optionele instellingen",
        "signOrder": "Ondertekeningsvolgorde",
        "submit": "Verzenden",
        "uploadTemplate": "Sjabloon uploaden",
        "uploadTemplateText": "*Houd er rekening mee dat onze service alleen moet worden gebruikt voor bepaalde categorieën documenten. Voor meer informatie, ",
        "referToTerms": "raadpleeg de gebruiksvoorwaarden",
        "createButtonText": "Aanmaken",
        "templateUploadSuccess": "Uw sjabloon is succesvol verzonden."
      },
      "myOrganisationPage": {
        "manageUsers": "Gebruikers beheren",
        "organisationReferralCode": "Organisatie-verwijzingscode",
        "shareLinkBusinessAccount": "Deel link om een zakelijk account aan te maken",
        "shareLinkPersonalAccount": "Deel link om een persoonlijk account aan te maken",
        "customizeLandingPage": "Aanpassen landingspagina",
        "headerLogo": "Header-logo",
        "lessThan300KB": "Moet kleiner zijn dan 300KB",
        "headerBackgroundColour": "Header-achtergrondkleur",
        "headerBackgroundDescription": "De kleur van de bovenste header",
        "bannerLogo": "Banner-logo",
        "lessThan500KB": "Moet kleiner zijn dan 500KB",
        "bannerBackground": "Banner-achtergrond",
        "organisationStamps": "Organisatiestempels",
        "delete": "Verwijderen",
        "businessProfile": "Bedrijfsprofiel",
        "companyName": "Bedrijfsnaam",
        "companySlogan": "Bedrijfsslogan",
        "companyDescription": "Bedrijfsbeschrijving",
        "update": "Bijwerken"
      },
      "viewTemplatePage": {
        "nameOfTemplate": "Naam van sjabloon",
        "createdBy": "Aangemaakt door",
        "dateCreated": "Aanmaakdatum",
        "version": "Versie",
        "lastEdited": "Laatst bewerkt",
        "lastViewed": "Laatst bekeken",
        "noOfDocumentsGeneratedFromTemplate": "Aantal documenten gegenereerd vanuit dit sjabloon",
        "templateUrl": "Sjabloon-URL",
        "downloadTemplateReport": "Sjabloonrapport downloaden",
        "requestToFillTemplate": "Verzoek om sjabloon in te vullen",
        "fillTemplateRequest": "Verzoek om sjabloon in te vullen",
        "fillTemplateRequestText": "Dit sjabloon is een sjabloon met één rol, bevestig alstublieft of u dit sjabloon wilt invullen.",
        "confirm":"Bevestigen",
        "back": "Terug",
        "viewTemplateActivity": "Bekijk sjabloonactiviteit",
        "deleteTemplate": "Sjabloon verwijderen",
        "createDocumentFromTemplate" : "Document aanmaken vanuit sjabloon",
        "backToDashboard":"Terug naar dashboard",
        "close": "Dichtbij"
      }
    }
  }